import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { getEnvironment } from '../environment/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageService {
    env = getEnvironment();

    private titleSubject = new BehaviorSubject<string>(this.env.title);
    title$ = this.titleSubject.asObservable();
    
    private breadcrumbsSubject = new BehaviorSubject<{url: string, title: string}[]>([]);
    breadcrumbs$ = this.breadcrumbsSubject.asObservable();

    private hasMenuSubject = new BehaviorSubject<boolean>(false);
    hasMenu$ = this.hasMenuSubject.asObservable();

    private activeMenuSubject = new BehaviorSubject<string|undefined>(undefined);
    activeMenu$ = this.activeMenuSubject.asObservable();

    constructor(
        private metaTitle: Title,
    ) {
    }

    set title(title: string | undefined) {
        if (title) {
            this.titleSubject.next(title);
            this.metaTitle.setTitle(`${title} | ${this.env.title}`);
        } else {
            this.titleSubject.next(this.env.title);
            this.metaTitle.setTitle(this.env.title);
        }
    }

    get title(): string {
        return this.titleSubject.value;
    }

    set breadcrumbs(breadcrumbs: {url: string, title: string}[]) {
        this.breadcrumbsSubject.next(breadcrumbs);
    }

    get breadcrumbs(): {url: string, title: string}[] {
        return this.breadcrumbsSubject.value;
    }

    set hasMenu(hasMenu: boolean) {
        this.hasMenuSubject.next(hasMenu);
    }

    get hasMenu(): boolean {
        return this.hasMenuSubject.value;
    }

    set activeMenu(activeMenu: string) {
        this.activeMenuSubject.next(activeMenu);
    }

    get activeMenu(): string|undefined {
        return this.activeMenuSubject.value;
    }
}
